import React from 'react';

export const SmileyFace = () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.00008 17.3333C13.6025 17.3333 17.3334 13.6024 17.3334 8.99999C17.3334 4.39762 13.6025 0.666656 9.00008 0.666656C4.39771 0.666656 0.666748 4.39762 0.666748 8.99999C0.666748 13.6024 4.39771 17.3333 9.00008 17.3333ZM11.549 10.9228C10.8959 11.7163 9.97883 12.1836 9.00009 12.1836C8.02135 12.1836 7.10422 11.7163 6.45118 10.9228C6.1002 10.4964 5.51383 10.271 5.01466 10.5073C4.5155 10.7436 4.29593 11.3463 4.61074 11.8001C5.59878 13.2243 7.17635 14.1836 9.00006 14.1836C10.8238 14.1836 12.4014 13.2243 13.3894 11.8001C13.7042 11.3463 13.4846 10.7436 12.9855 10.5073C12.4863 10.271 11.8999 10.4964 11.549 10.9228ZM10.6667 6.91666C10.6667 6.2263 11.2264 5.66666 11.9167 5.66666C12.6071 5.66666 13.1667 6.2263 13.1667 6.91666C13.1667 7.60701 12.6071 8.16666 11.9167 8.16666C11.2264 8.16666 10.6667 7.60701 10.6667 6.91666ZM6.08341 5.66666C5.39306 5.66666 4.83341 6.2263 4.83341 6.91666C4.83341 7.60701 5.39306 8.16666 6.08341 8.16666C6.77377 8.16666 7.33341 7.60701 7.33341 6.91666C7.33341 6.2263 6.77377 5.66666 6.08341 5.66666Z'
      fill='#19A0FF'
      fillOpacity='0.5'
    />
  </svg>
);
