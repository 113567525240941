import React from "react";
import sortBy from "lodash/sortBy";
import classnames from "classnames";
import VideoHostPlayer from "./VideoHostPlayer";
import { If } from "common";
import VideoAttendePlayer from "./VideoAttendePlayer";

const VideoWideScreen = ({
  meetingState,
  localVideoTrack,
  localShareTrack,
  getGrid,
  userAgoraId,
  showMeeting,
  switchList,
  localStreamStatus,
  ownerAgoraId,
  getAttendeeList,
}) => (
  <React.Fragment>
    <If
      condition={meetingState.isHostJoined && showMeeting()}
      render={() => {
        const currentVideoTrack = meetingState.user?.isCamera
          ? localVideoTrack
          : localShareTrack || meetingState.mainScreen.videoTrack;
        return (
          <VideoHostPlayer
            videoTrack={currentVideoTrack}
            isLocalUser={meetingState.mainScreen.isLocalUser}
            className={
              meetingState.showParticipantVideoList &&
              meetingState.participantList.filter(
                (participant) => participant.join
              ).length &&
              !meetingState.user?.isCamera
                ? getGrid()
                : "main-screen-full"
            }
          />
        );
      }}
    />
    <If
      condition={
        meetingState.participantList.filter((item) => item.join).length > 0 &&
        !meetingState.user?.isCamera
      }
      render={() => {
        const currentList = sortBy(meetingState.participantList, [
          "user.isCamera",
          "user.displayName",
        ]).filter(
          (participant) =>
            participant.join &&
            Number(meetingState.sharedId) !==
              Number(participant.user.ticketAgoraId) &&
            Number(ownerAgoraId) !== Number(participant.user.ticketAgoraId) &&
            Number(userAgoraId) !== Number(participant.user.ticketAgoraId)
        );
        const showHostAttendee = Boolean(
          meetingState.isHostJoined &&
            meetingState.hostAttendeeScreen &&
            showMeeting()
        );
        const showLocalAttendee =
          !meetingState.isHost &&
          Number(meetingState.sharedId) !== Number(userAgoraId);
        return (
          <div
            className={classnames(`attende-list ${getGrid()}`, {
              "attende-list-show": meetingState.showParticipantVideoList,
              "attende-list-hide": !meetingState.showParticipantVideoList,
              "show-arrows":
                currentList.length >
                meetingState.listPageCount * meetingState.grid,
              "show-split-arrows":
                meetingState.participantList.filter((item) => item.join)
                  .length > meetingState.listPageCount,
            })}
            id="attende-list"
          >
            <If
              condition={meetingState.currentPage !== 0}
              render={() => (
                <div
                  className="list-arrow-container top-position"
                  onClick={() =>
                    switchList(
                      true,
                      currentList.length + (!meetingState.isHost ? 1 : 0)
                    )
                  }
                >
                  <div className="list-top-arrow"></div>
                </div>
              )}
            />
            {/* If host is not presenter */}
            <If
              condition={showHostAttendee && meetingState.currentPage === 0}
              render={() => {
                return (
                  <VideoAttendePlayer
                    videoTrack={meetingState.hostAttendeeScreen.videoTrack}
                    user={meetingState.hostAttendeeScreen.user}
                    isLocalUser={meetingState.hostAttendeeScreen.isLocalUser}
                    streamStatus={
                      Number(ownerAgoraId) === Number(userAgoraId)
                        ? localStreamStatus
                        : meetingState.hostAttendeeScreen.status
                    }
                  />
                );
              }}
            />

            {/* For local user */}
            <If
              condition={showLocalAttendee && meetingState.currentPage === 0}
              render={() => {
                return (
                  <VideoAttendePlayer
                    videoTrack={localVideoTrack}
                    user={meetingState.user}
                    isLocalUser={true}
                    streamStatus={localStreamStatus}
                  />
                );
              }}
            />
            {getAttendeeList(showHostAttendee, showLocalAttendee, [
              ...currentList,
            ])}
            <If
              condition={
                meetingState.currentPage + 1 !==
                Math.ceil(
                  ((currentList.length + (!meetingState.isHost ? 1 : 0)) /
                    meetingState.listPageCount) *
                    meetingState.grid
                )
              }
              render={() => (
                <div
                  className="list-arrow-container bottom-position"
                  onClick={() =>
                    switchList(
                      false,
                      currentList.length + (!meetingState.isHost ? 1 : 0)
                    )
                  }
                >
                  <div className="list-bottom-arrow"></div>
                </div>
              )}
            />
          </div>
        );
      }}
    />
  </React.Fragment>
);

export default VideoWideScreen;
