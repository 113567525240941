import React, { useState } from 'react';
import { MessageInput } from 'stream-chat-react';

import './LiveEventChannelFooter.css';
import { LiveEventMessageInput } from './LiveEventMessageInput';

export const LiveEventChannelFooter = () => (
  <div className='live-event-footer__container'>
    <div className='live-event-footer__top'>
      <div className='live-event-footer__input'>
        <MessageInput
          focus
          grow
          Input={(props) => <LiveEventMessageInput {...props} sloMoDelay="0" />}
        />
      </div>
    </div>
  </div>
);
