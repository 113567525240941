import React from "react";
import { func, node, string, bool } from "prop-types";
import { Button } from "common";

const VideoControlIcon = ({
  isOn,
  title,
  className,
  onClick,
  onIcon,
  offIcon,
  isWaiting,
  disabled,
}) => (
  <Button
    color="videoAction"
    round
    justIcon
    className={className}
    title={`${isOn ? "Disable" : "Enable"} ${title}`}
    onClick={(e) => !disabled && onClick(e)}
    onMouseDown={(e) => e.preventDefault()}
  >
    {isWaiting ? (
      <img
        src={require("assets/img/loading-icon.svg")}
        width="24"
        height="24"
      />
    ) : isOn ? (
      onIcon
    ) : (
      offIcon
    )}
  </Button>
);

VideoControlIcon.propTypes = {
  onClick: func.isRequired,
  isOn: bool.isRequired,
  onIcon: node.isRequired,
  offIcon: node.isRequired,
  className: string.isRequired,
  title: string.isRequired,
};

export default VideoControlIcon;
