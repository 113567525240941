import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);

const getDurationString = (duration) => {
  if (!duration) {
    return '0:00 h';
  }

  const momentDuration = moment.duration(duration, "seconds");
  return `${momentDuration.hours()}:${momentDuration.minutes() === 0 ? '00' : momentDuration.minutes()} h`;
}

export {
  getDurationString
};
