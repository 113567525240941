import React from 'react';
import { object, func } from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import { If } from 'common';
import {
  Button
} from '../../common';
import {VIDEO_COMMAND_TYPE, MEETING_STATUS} from '../../constants/video';

import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";

const useStyles = makeStyles(javascriptStyles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const VideoAlert = ({alertNotification, setCommonState, manageAlertNotification, confirmTitle, showCancel, onCancel}) => {
  const classes = useStyles();

  const getAlertNotificationMessage = () => {
    switch (alertNotification?.message) {
      case VIDEO_COMMAND_TYPE.PRESENT:
        return 'The host would like to make you the presenter. Turn on video and mic?';
      case VIDEO_COMMAND_TYPE.UNMUTE_VIDEO:
        return 'The host would like to turn on your video. Do you approve it?';
      case VIDEO_COMMAND_TYPE.UNMUTE:
        return 'The host would like to turn on your microphone. Do you approve it?';
      case 'END_OF_SESSION_WITHOUT_HOST':
        return 'The host has not joined. If you want you can refund for this event or can wait for the host. Do you want to refund?'
      case MEETING_STATUS.FORCE_END_MEETING:
        return 'The meeting has finished. You will redirect to events page in 5 seconds';
      case 'REQUEST_PRESENT_SOMEONE':
        return 'You’re about to change the presenter to ';
      case 'REQUEST_REVOKE_PRESENT':
        return 'You’re about to take over the session';
      case MEETING_STATUS.DUPLICATE_USER:
        return 'Your account was just signed in from a different device. You will redirect to events page in 3 seconds';
      case MEETING_STATUS.CURTAIN_MEETING:
        return 'The meeting has not started yet. When you click "Let Attendee In" button. it will start.'
      default:
        return "UNEXPECTED MESSAGE"
    }
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + " " + classes.modalSmall
      }}
      open={Boolean(alertNotification?.isOpen)}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setCommonState({ alertNotification: null})}
      aria-labelledby="small-modal-slide-title"
      aria-describedby="small-modal-slide-description"
    >
      <DialogTitle
        id="small-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          simple
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          onClick={onCancel}
        >
          {" "}
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent
        id="small-modal-slide-description"
        className={classes.modalBody}
        style={{paddingTop: '0'}}
      >
        <If
          condition={Boolean(alertNotification?.title)}
          render={() => (
            <h3 className={classes.textCenter}>
              {alertNotification.title}
            </h3>
          )}
        />
        <h5 className={classes.textCenter}>
          {getAlertNotificationMessage()}
        </h5>
        <If
          condition={Boolean(alertNotification?.user)}
          render={() => (
            <div className="attendes-screens-holder bg-transparent">
              <div className="attendes-screens-holder-image">
                <img src={alertNotification.user.imageUrl} />
              </div>
              <div className="attendes-screens-holder-title">
                <span>
                  {alertNotification.user.displayName}
                </span>
              </div>
            </div>
          )}
        />
      </DialogContent>
      <DialogActions
        className={
          classes.modalFooter + " " + classes.modalFooterCenter
        }
      >
        {showCancel &&
          <Button
            onClick={onCancel}
            link
            className={classes.modalSmallFooterFirstButton}
          >
            No
          </Button>
        }
        
        <Button
          onClick={manageAlertNotification}
          color="primary"
          round
          className={
            classes.modalSmallFooterFirstButton +
            " " +
            classes.modalSmallFooterSecondButton
          }
        >
          <span style={{fontWeight: '700'}}>
            {alertNotification?.confirmTitle || confirmTitle || 'Yes'}
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

VideoAlert.propTypes = {
  alertNotification: object,
  setCommonState: func.isRequired,
  manageAlertNotification: func.isRequired
};

VideoAlert.defaultProps = {
  alertNotification: null
};

export default VideoAlert;
