/* eslint-disable */
import React, { useState } from "react";
import { oneOf } from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../service/firebase";
import { AuthUserContext } from "../../service/session";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import Close from "@material-ui/icons/Close";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";
import LockOpen from "@material-ui/icons/LockOpen";
import CalendarToday from "@material-ui/icons/CalendarToday";

// core components
import { Button, Dropdown, If } from "../../common";

import styles from "../../assets/jss/material-kit-pro-react/components/headerLinksStyle";
import modalStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";

const useStyles = makeStyles(styles);
const modalUseStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const HeaderLinks = (props) => {
  const classes = useStyles();
  const modalClasses = modalUseStyles();
  const [modal, setModal] = useState(false);

  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <AuthUserContext.Consumer>
        {(authUser) => {
          const dropDownMenu = authUser?.emailVerified
            ? ["Logout"]
            : ["Logout", "Verify Email"];

          return authUser ? (
            <If
              condition={Boolean(props.isMobile)}
              otherwise={() => (
                <React.Fragment>
                  <Dialog
                    classes={{
                      root: modalClasses.modalRoot,
                      paper: modalClasses.modal,
                    }}
                    open={Boolean(modal)}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setModal(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={modalClasses.modalHeader}
                    >
                      <Button
                        simple
                        className={modalClasses.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => setModal(false)}
                      >
                        {" "}
                        <Close className={modalClasses.modalClose} />
                      </Button>
                      <h4
                        className={modalClasses.modalTitle}
                        style={{ fontWeight: "500" }}
                      >
                        Email Verification
                      </h4>
                    </DialogTitle>
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={modalClasses.modalBody}
                      style={{ textAlign: "center" }}
                    >
                      <p>
                        {`We have sent a verification link to ${authUser.email}.`}
                      </p>
                      <p>Please click on the link to verify your email.</p>
                      <Button
                        round
                        color="primary"
                        onClick={() => setModal(false)}
                      >
                        OK
                      </Button>
                    </DialogContent>
                  </Dialog>
                  <ListItem className={classes.listItem}>
                    <Link
                      color="transparent"
                      to="/my-events"
                      className={classes.navLink}
                    >
                      <CalendarToday className={classes.icons} /> My Events
                    </Link>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Dropdown
                      buttonText={
                        <React.Fragment>
                          <Person className={classes.icons} />{" "}
                          {authUser.displayName}
                        </React.Fragment>
                      }
                      buttonProps={{
                        block: true,
                        color: "transparent",
                      }}
                      onClick={(selected) => {
                        if (selected === "Logout") {
                          props.firebase.doSignOut().then(() => {
                            props.history.push("/");
                          });
                        } else if (selected === "Verify Email") {
                          props.firebase.doEmailVerification().then(() => {
                            setModal(true);
                          });
                        }
                      }}
                      dropPlacement="bottom"
                      dropdownList={dropDownMenu}
                    />
                  </ListItem>
                </React.Fragment>
              )}
              render={() => (
                <React.Fragment>
                  <ListItem className={classes.listItem}>
                    <Button color="transparent" className={classes.navLink}>
                      <Person className={classes.icons} />{" "}
                      {authUser.displayName}
                    </Button>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Link
                      color="transparent"
                      to="/my-events"
                      className={classes.navLink}
                    >
                      <CalendarToday className={classes.icons} /> My Events
                    </Link>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Button
                      onClick={() =>
                        props.firebase.doSignOut().then(() => {
                          props.history.push("/");
                        })
                      }
                      color="transparent"
                      className={classes.navLink}
                    >
                      <ExitToApp className={classes.icons} /> Logout
                    </Button>
                  </ListItem>
                </React.Fragment>
              )}
            />
          ) : (
            <ListItem className={classes.listItem}>
              <Button
                href="/login"
                color="transparent"
                className={classes.navLink}
              >
                <LockOpen className={classes.icons} /> Login
              </Button>
            </ListItem>
          );
        }}
      </AuthUserContext.Consumer>
    </List>
  );
};

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};

export default compose(withRouter, withFirebase)(HeaderLinks);
