import { MEETING_STATUS } from 'constants/video';
import {handleActions} from 'redux-actions';
import {ActionTypes as MeetingActionTypes} from '../actions/meeting';

const initialState = {
  showParticipant: false,
  participantList: [],
  isHost : false,
  locked: false,
  sharedId: null,
  alertNotification: {
    isOpen: false,
    message: ''
  },
  isHostJoined: false,
  meetingStatus : MEETING_STATUS.NOT_STARTED,
  user: null,
  showParticipantVideoList: true,
  waitingSeconds: 0,
  extraTime: 0,
  dialogRespondDurationInSecs: 0, 
  heartbeatPeriodInSecs: 0,
  mainScreen: {
    videoTrack: null,
    audioTrack: null
  },
  grid: 1
};

const updateMeetingReducer = (state, action) => ({
  ...state,
  ...action.payload
});

export default handleActions({
  [MeetingActionTypes.UPDATE_MEETING] : updateMeetingReducer
}, initialState);
