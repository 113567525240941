import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Parallax, GridContainer, GridItem, Button, If } from "../../common";
import { compose } from "recompose";
import { withFirebase } from "../../service/firebase";
import { withAuthorization } from "../../service/session";

import profilePageStyle from "../../assets/jss/material-kit-pro-react/views/profilePageStyle";

const useStyles = makeStyles(profilePageStyle);

const Profile = (props) => {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const listener = props.firebase.onAuthUserListener((authUser) => {
      setUser(authUser);
    });

    return () => {
      listener();
    };
  }, [props.firebase]);

  return (
    <React.Fragment>
      <Parallax
        image={require("assets/img/bg_image.jpg")}
        filter="info"
        className={classes.parallax}
      />
      <div className={classes.container}>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  <img
                    src={user?.userImageUrl}
                    alt="..."
                    className={imageClasses}
                  />
                </div>
                <div className={classes.name}>
                  <h3 className={classes.title}>{user?.displayName}</h3>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </React.Fragment>
  );
};

const condition = (authUser) => !!authUser;

export default compose(withFirebase, withAuthorization(condition))(Profile);
