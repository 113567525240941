import {all, fork} from 'redux-saga/effects';
import meeting from './meeting';

const rootSagaClosure = () => function* rootSaga () {
  yield all([
    fork(meeting)
  ]);
};

export default rootSagaClosure;
