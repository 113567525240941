import { useState, useEffect, useCallback, useRef } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  VIDEO_PROPERTIES,
  STREAM_FALLBACK_TYPE,
  STREAM_ROLES,
} from "../../../constants/video";

const otherChannels = {};

const useAgora = (client) => {
  const [localVideoTrack, setLocalVideoTrack] = useState();
  const [localAudioTrack, setLocalAudioTrack] = useState();
  const [localShareTrack, setLocalShareTrack] = useState();
  const [localShareAudiTrack, setLocalShareAudioTrack] = useState();
  const localShareTrackRef = useRef();
  const localShareAudioTrackRef = useRef();

  const [joinState, setJoinState] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState([]);

  const [remoteUsersStatus, setRemoteUsersStatus] = useState(null);

  const createLocalTracks = async (configuration) => {
    const [
      microphoneTrack,
      cameraTrack,
    ] = await AgoraRTC.createMicrophoneAndCameraTracks(
      {
        AEC: true,
        AGC: true,
        ANS: true,
      },
      {
        encoderConfig: {
          width: Number(configuration.videoWidth),
          height: Number(configuration.videoHeight),
          frameRate: Number(configuration.videoFps),
          bitrateMin: Number(configuration.videoBitrateMin),
          bitrateMax: Number(configuration.videoBitrateMax),
        },
      }
    );

    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);
    return [microphoneTrack, cameraTrack];
  };

  const playShareScreen = async (isOnlyAudio) => {
    const screenTracks = await AgoraRTC.createScreenVideoTrack(
      { encoderConfig: "720p_1" },
      "auto"
    );

    let screenVideoTrack = screenTracks;
    let screenAudioTrack = null;
    if (screenTracks.length) {
      screenVideoTrack = screenTracks[0];
      screenAudioTrack = screenTracks[1];
    }

    if (isOnlyAudio) {
      setLocalShareAudioTrack(screenAudioTrack);
    } else {
      setLocalShareTrack(screenVideoTrack);
    }

    localShareTrackRef.current = screenVideoTrack;

    if (screenAudioTrack && !isOnlyAudio) {
      localShareAudioTrackRef.current = screenAudioTrack;
      await client.unpublish(localVideoTrack);
      await client.publish([screenVideoTrack, screenAudioTrack]);
    } else if (screenAudioTrack && isOnlyAudio) {
      await client.publish(screenAudioTrack);
    } else if (!isOnlyAudio) {
      await client.unpublish(localVideoTrack);
      await client.publish(screenVideoTrack);
    }

    if (screenVideoTrack && !isOnlyAudio) {
      screenVideoTrack.on("track-ended", stopShareScreen);
    }

    if (screenAudioTrack) {
      screenAudioTrack.on("track-ended", stopShareScreen);
    }

    if (isOnlyAudio && !screenAudioTrack) {
      stopShareScreen();
    }
  };

  const stopShareScreen = async () => {
    if (localShareTrackRef.current) {
      localShareTrackRef.current.stop();
      localShareTrackRef.current.close();
      localShareTrackRef.current.off("track-ended", () => {
        console.error("track-ended off");
      });

      if (localShareAudioTrackRef.current) {
        localShareAudioTrackRef.current.stop();
        localShareAudioTrackRef.current.close();
        localShareAudioTrackRef.current.off("track-ended", () => {
          console.error("track-ended off");
        });
        await client.unpublish([
          localShareTrackRef.current,
          localShareAudioTrackRef.current,
        ]);
      } else {
        await client.unpublish(localShareTrackRef.current);
      }

      await client.unpublish(localShareTrackRef.current);
      await client.publish(localVideoTrack);

      localShareTrackRef.current = null;
      localShareAudioTrackRef.current = null;
      setLocalShareTrack(null);
      setLocalShareAudioTrack(null);
    }
  };

  const join = async (
    appid,
    channel,
    tokens,
    uid,
    configuration,
    channelList
  ) => {
    if (!client) return;
    try {
      const [microphoneTrack, cameraTrack] = await createLocalTracks(
        configuration
      );

      await client.join(appid, channel, tokens[channel], Number(uid));

      try {
        await client.enableDualStream();
      } catch (error) {
        console.error(error, "EERRROROROOR");
      }

      await client.publish([microphoneTrack, cameraTrack]);

      AgoraRTC.getDevices(
        (devices) => {
          console.error(devices);
        },
        (error) => {
          console.error(error, "ERRORR");
        }
      );

      setJoinState(true);

      channelList.forEachAsync(async (chnl) => {
        if (chnl.channelId !== channel) {
          const newClient = AgoraRTC.createClient({
            codec: VIDEO_PROPERTIES.CODEC,
            mode: VIDEO_PROPERTIES.MODE,
            role: STREAM_ROLES.AUDIENCE,
          });

          setListenners(newClient);
          console.error(tokens, chnl.channelId, channel);
          await newClient.join(
            appid,
            chnl.channelId,
            tokens[chnl.channelId],
            Number(uid)
          );
          otherChannels[chnl.channelId] = newClient;
        }
      });
    } catch (error) {
      console.error(error, "ERRROR");
    }
  };

  const leave = useCallback(async () => {
    if (localAudioTrack) {
      localAudioTrack.stop();
    }

    if (localVideoTrack) {
      localVideoTrack.stop();
    }

    if (window.videoTrack) {
      window.videoTrack.stop();
      window.videoTrack = null;
    }

    if (window.audioTrack) {
      window.audioTrack.stop();
      window.audioTrack = null;
    }

    setRemoteUsers([]);
    setJoinState(false);

    leaveChannels();

    window.location.href = "/events";
  }, [localAudioTrack, localVideoTrack, setRemoteUsers, setJoinState]);

  const leaveChannels = async () => {
    await client.leave();
    Object.keys(otherChannels).forEachAsync(async (channel) => {
      await otherChannels[channel].leave();
    });

    disposeListenner();
  };

  const setListenners = (currentClient) => {
    const handleUserPublished = async (user, mediaType) => {
      await currentClient.subscribe(user, mediaType);
      await currentClient.setStreamFallbackOption(
        Number(user.uid),
        STREAM_FALLBACK_TYPE.AUDIO_ONLY
      );
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers((remoteUsers) => {
        const currentUsers = [...remoteUsers];
        const userIndex = currentUsers.findIndex(
          (u) => Number(u.uid) === Number(user.uid)
        );
        if (userIndex > -1) {
          currentUsers[userIndex] = user;
        }

        return currentUsers;
      });
    };

    const handleUserUnpublished = (user) => {
      setRemoteUsers((remoteUsers) => {
        const currentUsers = [...remoteUsers];
        const userIndex = currentUsers.findIndex(
          (u) => Number(u.uid) === Number(user.uid)
        );
        if (userIndex > -1) {
          currentUsers[userIndex] = user;
        }

        return currentUsers;
      });
    };

    const handleUserJoined = (user) => {
      setRemoteUsersStatus({
        joined: true,
        user,
      });
      setRemoteUsers((remoteUsers) => {
        const currentUsers = [...remoteUsers];
        const userIndex = currentUsers.findIndex(
          (u) => Number(u.uid) === Number(user.uid)
        );
        if (userIndex === -1) {
          currentUsers.push(user);
        }

        return currentUsers;
      });
    };

    const handleUserLeft = (user) => {
      setRemoteUsersStatus({
        joined: false,
        user,
      });
      setRemoteUsers((remoteUsers) => {
        const currentUsers = [...remoteUsers];
        const userIndex = currentUsers.findIndex(
          (u) => Number(u.uid) === Number(user.uid)
        );
        if (userIndex !== -1) {
          currentUsers.splice(userIndex, 1);
        }

        return currentUsers;
      });
    };

    currentClient.on("user-published", handleUserPublished);
    currentClient.on("user-unpublished", handleUserUnpublished);
    currentClient.on("user-joined", handleUserJoined);
    currentClient.on("user-left", handleUserLeft);
  };

  const disposeListenner = () => {
    client.removeAllListeners();
    Object.keys(otherChannels).forEach((channel) => {
      otherChannels[channel].removeAllListeners();
    });
  };

  useEffect(() => {
    setRemoteUsers(client.remoteUsers);

    setListenners(client);

    return () => {
      disposeListenner();
    };
  }, []);

  return {
    localAudioTrack,
    localVideoTrack,
    localShareTrack,
    joinState,
    remoteUsersStatus,
    leave,
    join,
    remoteUsers,
    playShareScreen,
    stopShareScreen,
    localShareAudiTrack,
  };
};

export default useAgora;
