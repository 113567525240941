import React from 'react';
import {bool, func, node, oneOfType} from 'prop-types';

const propTypes = {
  children  : oneOfType([func, node]),
  condition : oneOfType([bool, func]).isRequired,
  otherwise : func,
  render    : func
};

const defaultProps = {
  children  : null,
  otherwise : () => null
};

const displayName = 'If';

const isEmptyChildren = children => React.Children.count(children) === 0;

// eslint-disable-next-line complexity
const If = ({children, component, condition, otherwise, render}) => {
  const evaluatedCondition = typeof condition === 'function' ? condition() : condition;

  if (evaluatedCondition) {
    if (component) {
      return React.createElement(component);
    }

    if (render) {
      return render();
    }

    if (children) {
      if (typeof children === 'function') {
        return children();
      }

      return isEmptyChildren(children) ? null : React.Children.only(children);
    }

    return null;
  }

  return otherwise();
};

If.displayName = displayName;
If.propTypes = propTypes;
If.defaultProps = defaultProps;

export default If;
