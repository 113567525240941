import {connect} from 'react-redux';
import {getMeetingState} from '../../store/selectors/meeting';
import meetingActions from '../../store/actions/meeting';
import Meeting from './Meeting';

const mapStateToProps = state => ({
  meetingState : getMeetingState(state)
});

const mapDispatchToProps = {
  updateMeetingState: meetingActions.updateMeeting
};

export default connect(mapStateToProps, mapDispatchToProps)(Meeting);
