import React from "react";
import { If, GridContainer, GridItem } from "../../../common";
import VideoCVCardTitle from "./VideoCVCardTitle";
import { VIDEO_CV_TYPE } from "../../../constants/video";

const VideoCVSkillCard = ({ data }) => (
  <React.Fragment>
    <VideoCVCardTitle data={data} />
    <div>
      <If
        condition={data.cvSectionType === VIDEO_CV_TYPE.languages.KEY}
        render={() => (
          <div className="font-size-xs">
            {data.languages.map((lang, index) => (
              <p
                key={index}
                style={{ margin: "0" }}
              >{`${lang.name} - ${lang.level}`}</p>
            ))}
          </div>
        )}
      />
      <If
        condition={data.cvSectionType === VIDEO_CV_TYPE.skills.KEY}
        render={() => (
          <div className="font-size-xs">
            {data.skills.map((skill, index) => (
              <p key={index} style={{ margin: "0" }}>
                {skill}
              </p>
            ))}
          </div>
        )}
      />
      <If
        condition={data.cvSectionType === VIDEO_CV_TYPE.certificates.KEY}
        render={() => (
          <div className="font-size-xs">
            {data.certificates.map((cert, index) => (
              <p key={index} style={{ margin: "0" }}>
                {cert}
              </p>
            ))}
          </div>
        )}
      />
      <If
        condition={data.cvSectionType === VIDEO_CV_TYPE.licenses.KEY}
        render={() => (
          <div className="font-size-xs">
            {data.licenses.map((lic, index) => (
              <p key={index} style={{ margin: "0" }}>
                {lic}
              </p>
            ))}
          </div>
        )}
      />
    </div>
  </React.Fragment>
);

export default VideoCVSkillCard;
