import React, { useCallback } from "react";
import classnames from "classnames";
import { VIDEO_CV_TYPE } from "../../../constants/video";
import VideoCVCoverCard from "./VideoCVCoverCard";
import VideoCVInstitutionCard from "./VideoCVInstitutionCard";
import VideoCVSkillCard from "./VideoCVSkillCard";

const VideoCVCard = ({ data, isActive, onClick, isHeader }) => {
  const getCard = useCallback(() => {
    switch (data.cvSectionType) {
      case VIDEO_CV_TYPE.experience.KEY:
      case VIDEO_CV_TYPE.volunteering.KEY:
      case VIDEO_CV_TYPE.education.KEY:
        return <VideoCVInstitutionCard data={data} />;
      case VIDEO_CV_TYPE.cover.KEY:
      case VIDEO_CV_TYPE.aboutMe.KEY:
      case VIDEO_CV_TYPE.custom.KEY:
        return <VideoCVCoverCard data={data} isHeader={isHeader} />;
      case VIDEO_CV_TYPE.licenses.KEY:
      case VIDEO_CV_TYPE.certificates.KEY:
      case VIDEO_CV_TYPE.skills.KEY:
      case VIDEO_CV_TYPE.languages.KEY:
        return <VideoCVSkillCard data={data} />;
      default:
        return <div>Wrong Type</div>;
    }
  }, [data, isHeader]);

  return (
    <div
      className={classnames("video-cv-navigation", {
        active: isActive,
        "content-header": isHeader,
      })}
      onClick={() => !isHeader && onClick()}
    >
      {getCard()}
    </div>
  );
};

export default VideoCVCard;
