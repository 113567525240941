/*eslint-disable*/
import React, { useCallback } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Person from "@material-ui/icons/Person";

import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../service/firebase";
import { withAuthorization } from "../../service/session";
import "./Login.css";

import {
  GridContainer,
  GridItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Snackbar,
  If,
} from "../../common";

import loginPageStyle from "../../assets/jss/material-kit-pro-react/views/loginPageStyle";

const useStyles = makeStyles(loginPageStyle);

const Login = (props) => {
  const [formError, setFormError] = React.useState(null);
  const [authForm, setAuthForm] = React.useState("LOGIN");
  const [loginForm, setLoginForm] = React.useState({
    email: null,
    password: null,
  });

  const classes = useStyles();

  const onLoginWithGoogle = (event) => {
    setFormError(null);
    props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        props.history.push("/");
      })
      .catch((error) => {
        console.error(error, "GOOGLE");
      });

    event.preventDefault();
  };

  const onLoginWithApple = (event) => {
    setFormError(null);
    props.firebase
      .doSignInWithApple()
      .then((socialAuthUser) => {
        props.history.push("/");
      })
      .catch((error) => {
        console.error(error, "APPLE");
      });

    event.preventDefault();
  };

  const validateForm = useCallback(() => {
    switch (authForm) {
      case "REGISTER":
        return loginForm.username && loginForm.email && loginForm.password;
      case "LOGIN":
        return loginForm.email && loginForm.password;
      default:
        return loginForm.email;
    }
  }, [authForm, loginForm]);

  const onFormChange = (value) => {
    setFormError(null);
    const currentFrom = {
      ...loginForm,
      ...value,
    };

    if (
      authForm === "REGISTER" &&
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(currentFrom.password)
    ) {
      setFormError(
        "Password should include at least one upper case, one lower case, one digit and minimum eight in length"
      );
    } else if (
      authForm === "REGISTER" &&
      currentFrom.password &&
      currentFrom.re_password &&
      currentFrom.password !== currentFrom.re_password
    ) {
      setFormError("Password do not match");
    }
    setLoginForm(currentFrom);
  };

  const onLoginWithEmail = () => {
    setFormError(null);
    props.firebase
      .doSignInWithEmailAndPassword(loginForm.email, loginForm.password)
      .then((socialAuthUser) => {
        props.history.push("/");
      })
      .catch((error) => {
        console.error("error", error);
        setFormError(error.message);
      });
  };

  const onRegesterWithEmail = () => {
    setFormError(null);
    props.firebase
      .doCreateUserWithEmailAndPassword(
        loginForm.username,
        loginForm.email,
        loginForm.password
      )
      .then((socialAuthUser) => {
        props.history.push("/");
      })
      .catch((error) => {
        console.error("error", error);
        setFormError(error.message);
      });
  };

  const onResetPassword = () => {
    setFormError(null);
    props.firebase
      .doPasswordReset(loginForm.email)
      .then((socialAuthUser) => {
        setFormError("Please check your mailbox");
      })
      .catch((error) => {
        setFormError(error.message);
      });
  };

  const handleSubmit = useCallback(
    (event) => {
      if (authForm === "LOGIN") {
        onLoginWithEmail();
      } else if (authForm === "RESET_PASSWORD") {
        onResetPassword();
      } else if (authForm === "REGISTER") {
        onRegesterWithEmail();
      }
      event.preventDefault();
    },
    [authForm, loginForm]
  );

  return (
    <div className={classes.pageHeader}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={6}>
            <Card>
              <form
                className={classes.form}
                onSubmit={(event) => !formError && handleSubmit(event)}
              >
                <CardHeader
                  color="primary"
                  signup
                  className={classes.cardHeader}
                >
                  <h4
                    className={classes.cardTitle}
                    style={{ textAlign: "center", paddingTop: "20px" }}
                  >
                    {authForm === "LOGIN" && "Login"}
                    {authForm === "RESET_PASSWORD" && "Reset Password"}
                    {authForm === "REGISTER" && "Sign up"}
                  </h4>
                  <div className={classes.socialLine}>
                    <Button
                      justIcon
                      color="transparent"
                      className={classes.iconButtons}
                      onClick={(e) => onLoginWithApple(e)}
                    >
                      <span className="fab fa-apple" />
                    </Button>
                    <Button
                      justIcon
                      color="transparent"
                      className={classes.iconButtons}
                      onClick={(e) => onLoginWithGoogle(e)}
                    >
                      <span className="fab fa-google-plus-g" />
                    </Button>
                  </div>
                </CardHeader>
                <If
                  condition={authForm === "LOGIN"}
                  render={() => (
                    <CardBody signup>
                      <Input
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Email...",
                          type: "email",
                          name: "email",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          onChange: (event) =>
                            onFormChange({
                              email: event.target.value,
                            }),
                        }}
                      />
                      <Input
                        id="pass"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Password",
                          type: "password",
                          name: "password",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                          onChange: (event) =>
                            onFormChange({
                              password: event.target.value,
                            }),
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          simple
                          color="primary"
                          style={{ padding: 0, margin: 0 }}
                          size="sm"
                          onClick={() => setAuthForm("REGISTER")}
                        >
                          Sign up
                        </Button>
                        <Button
                          simple
                          color="primary"
                          style={{ padding: 0, margin: 0 }}
                          size="sm"
                          onClick={() => setAuthForm("RESET_PASSWORD")}
                        >
                          Forgot password?
                        </Button>
                      </div>
                    </CardBody>
                  )}
                />
                <If
                  condition={authForm === "RESET_PASSWORD"}
                  render={() => (
                    <CardBody signup>
                      <Input
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Email...",
                          type: "email",
                          name: "email",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          onChange: (event) =>
                            onFormChange({
                              email: event.target.value,
                            }),
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          simple
                          color="primary"
                          style={{ padding: 0, margin: 0 }}
                          size="sm"
                          onClick={() => setAuthForm("REGISTER")}
                        >
                          Sign up
                        </Button>
                        <Button
                          simple
                          color="primary"
                          style={{ padding: 0, margin: 0 }}
                          size="sm"
                          onClick={() => setAuthForm("LOGIN")}
                        >
                          Login
                        </Button>
                      </div>
                    </CardBody>
                  )}
                />
                <If
                  condition={authForm === "REGISTER"}
                  render={() => (
                    <CardBody signup>
                      <Input
                        id="username"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Name",
                          type: "text",
                          name: "username",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          onChange: (event) =>
                            onFormChange({
                              username: event.target.value,
                            }),
                        }}
                      />
                      <Input
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Email...",
                          type: "email",
                          name: "email",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          onChange: (event) =>
                            onFormChange({
                              email: event.target.value,
                            }),
                        }}
                      />
                      <Input
                        id="pass"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Password",
                          type: "password",
                          name: "password",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                          onChange: (event) =>
                            onFormChange({
                              password: event.target.value,
                            }),
                        }}
                      />
                      <Input
                        id="pass"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          placeholder: "Confirm Password",
                          type: "password",
                          name: "re-password",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                          onChange: (event) =>
                            onFormChange({
                              re_password: event.target.value,
                            }),
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          simple
                          color="primary"
                          style={{ padding: 0, margin: 0 }}
                          size="sm"
                          onClick={() => setAuthForm("LOGIN")}
                        >
                          Login
                        </Button>
                        <Button
                          simple
                          color="primary"
                          style={{ padding: 0, margin: 0 }}
                          size="sm"
                          onClick={() => setAuthForm("RESET_PASSWORD")}
                        >
                          Forgot password?
                        </Button>
                      </div>
                    </CardBody>
                  )}
                />
                <div
                  className={classes.textCenter}
                  style={{ marginTop: "20px" }}
                >
                  <Button
                    color="primary"
                    size="sm"
                    disabled={formError || !validateForm()}
                    style={{ marginBottom: "20px" }}
                    type="submit"
                  >
                    {authForm === "LOGIN" && "Login"}
                    {authForm === "RESET_PASSWORD" && "Reset Password"}
                    {authForm === "REGISTER" && "Sign up"}
                  </Button>
                </div>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
        <If
          condition={Boolean(formError)}
          render={() => (
            <Snackbar
              message={
                <span>
                  <b>WARNING ALERT:</b> {formError}
                </span>
              }
              color="danger"
              icon="info_outline"
            />
          )}
        />
      </div>
    </div>
  );
};

const condition = (authUser) => !authUser;

export default compose(
  withRouter,
  withFirebase,
  withAuthorization(condition)
)(Login);
