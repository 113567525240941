/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { withFirebase } from "../../service/firebase";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import {
  GridContainer,
  GridItem,
  Snackbar,
  If,
  MultiItemCarousel,
  Primary,
} from "../../common";

import productStyle from "../../assets/jss/material-kit-pro-react/views/productStyle";
import cardStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/sectionCards";
import EventItem from "../Events/EventItem";
import CATEGORIES from "constants/category";
import { Link } from "react-router-dom";

const useStyles = makeStyles(productStyle);
const cardsUseStyles = makeStyles(cardStyle);

const Features = (props) => {
  const classes = useStyles();
  const cardClasses = cardsUseStyles();
  const [eventList, setEventList] = React.useState(null);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    props.firebase.getFeatureEvents((list) => {
      setEventList(list);
      setLoading(false);
    });
  }, []);

  return (
    <If
      condition={Boolean(eventList)}
      otherwise={() => (
        <div className="vidiolo-loading">
          <CircularProgress />
          <Primary>Loading...</Primary>
        </div>
      )}
      render={() => (
        <div className={classes.productPage}>
          <div className={classNames(classes.section, classes.sectionGray)}>
            <div className={classes.container}>
              <div className={classNames(classes.main)}>
                <GridContainer>
                  <If
                    condition={eventList.length === 0 && !loading}
                    render={() => (
                      <Snackbar
                        message={
                          <span>
                            To browse and buy events please login to the Vidiolo
                            App which is available on the Google Play Store or
                            in the Apple App Store if you haven’t downloaded it
                            yet.
                          </span>
                        }
                        color="info"
                        icon="info_outline"
                      />
                    )}
                  />
                  {eventList &&
                    Object.values(eventList).map((eventItem) => (
                      <GridItem xs={12} key={eventItem.categoryKey}>
                        <h4
                          style={{
                            marginTop: "20px",
                            marginBottom: "-10px",
                            paddingLeft: "20px",
                            fontWeight: 500,
                            color: "#000000",
                          }}
                        >
                          <Link
                            style={{ color: "#000000" }}
                            to={`/category/${
                              CATEGORIES[eventItem.categoryKey].key
                            }`}
                          >
                            {CATEGORIES[eventItem.categoryKey].title}
                          </Link>
                        </h4>
                        <MultiItemCarousel>
                          {eventItem.events.map((data) => (
                            <EventItem
                              key={data.eventId}
                              event={data}
                              cardClasses={cardClasses}
                              agoraUserId={data.ticketAgoraId}
                              isListItem
                            />
                          ))}
                        </MultiItemCarousel>
                      </GridItem>
                    ))}
                </GridContainer>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default withFirebase(Features);
