import React, { useEffect, useRef } from 'react';

const VideoHostPlayer = (props) => {
  const container = useRef(null);

  useEffect(() => {
    if (!container.current) return;
    
    if (props.videoTrack) {
      props.videoTrack.play(container.current, {fit: 'contain'});
    }
  
    return () => {
      if (props.videoTrack) {
        while (container.current.firstChild) {
          container.current.removeChild(container.current.firstChild)
        }
      }
    };
  }, [container, props.videoTrack]);

  return (
    <div
      ref={container}
      className={props.containerSize ? '' : `main-screen ${props.className}`}
      style={{width: props?.containerSize?.imageWidth + 'px', height: props?.containerSize?.imageHeight + 'px'}}
    />
  );
}

export default VideoHostPlayer;
