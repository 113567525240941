import {createAction} from 'redux-actions';

const PREFIX = 'MEETING::';

const ActionTypes = {
  UPDATE_MEETING : `${PREFIX}UPDATE_MEETING`
};

const Actions = {
  updateMeeting : createAction(ActionTypes.UPDATE_MEETING)
};

export {
  ActionTypes
};

export default Actions;
