const grid = (w, h, numUsers, minHeight, maxHeight, aspect) => {
  var width = Math.max(w, 800);
  var height = Math.max(h, 500);
  var numRowsMax = Math.floor(height / minHeight);
  var numColsMax = Math.floor(width / (minHeight * aspect));
  var numUsersMax = numRowsMax * numColsMax;
  var numRowsMin = Math.floor(height / maxHeight);
  var numColsMin = Math.floor(width / (maxHeight * aspect));
  var numUsersMin = numRowsMin * numColsMin;
  var numPages = Math.ceil(numUsers / numUsersMax);
  var pages = [];

  if (numPages > 1) {
    var remain = numUsers;
    for (let i = 0; i < numPages; i++) {
      pages.push(Math.ceil(remain / (numPages - i)));
      remain -= Math.ceil(remain / (numPages - i));
    }
  } else {
    pages.push(numUsers);
  }

  var pageCompositions = [];
  for (let i = 0; i < pages.length; i++) {
    var nU = pages[i];
    var nUmax = numUsersMax;
    var nUmin = numUsersMin;
    var mxH = maxHeight;
    var mnH = minHeight;
    var nRmax,nRmin,nCmax,nCmin;
  
    var uB;
    while (nU <= nUmax && mnH < maxHeight) {
      mnH += 2;
      uB = userBounds(width,height,mnH,mxH,aspect);
      nUmax = uB['numUsersMax'];
      nUmin = uB['numUsersMin'];
      nRmax = uB['numRowsMax'];
      nRmin = uB['numRowsMin'];
      nCmax = uB['numColsMax'];
      nCmin = uB['numColsMin'];
    }  

    mnH -=2;
    uB = userBounds(width,height,mnH,mxH,aspect);
    pageCompositions.push({
      rows: uB['numRowsMax'],
      cols: uB['numColsMax'],
      imageHeight: mnH,
      imageWidth: Math.floor(mnH*aspect),
      numUsers: pages[i]
    });
  }
  return pageCompositions;
}

const userBounds = (width, height, minHeight, maxHeight, aspect) => {
  var numRowsMax = Math.floor(height / minHeight);
  var numColsMax = Math.floor(width / (minHeight * aspect));
  var numUsersMax = numRowsMax * numColsMax;
  var numRowsMin = Math.floor(height / maxHeight);
  var numColsMin = Math.floor(width / (maxHeight * aspect));
  var numUsersMin = numRowsMin * numColsMin;

  return {
    numUsersMax,
    numUsersMin,
    numRowsMax,
    numRowsMin,
    numColsMax,
    numColsMin
  };
}

export default grid;