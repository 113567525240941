import React, { useState, useEffect, useCallback } from "react";
import Star from "@material-ui/icons/StarRate";

const EventProfileStar = ({ starCount }) => (
  <span>
    <Star style={{ verticalAlign: "bottom", color: "orange" }} />
    {starCount || "New"}
  </span>
);

export default EventProfileStar;
