import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faComments } from "@fortawesome/free-solid-svg-icons";
import { If } from "../../common";
import { formatSecondsAsMinutes } from "./helper/helper";
import { MEETING_STATUS } from "../../constants/video";
import CountDown from "../CountDown";

const VideoHeader = ({
  event,
  isHost,
  isHostJoined,
  waitingSeconds,
  serverTime,
  meetingStatus,
  handleMeetingCountDownCallback,
  handleForceEndMeetingCountDownCallback,
  onClickAttendeesList,
  participantListCount,
  extraTime,
  handleParticipantList,
  showParticipantList,
  switchGridView,
  isGrid,
  showParticipantVideoList,
}) => (
  <div className="video-header">
    <div className="video-header-timer">
      <div className="video-header-participant">
        <div className="video-header-timer-item video-header-timer-item-pointer">
          <span
            className={`video-header-timer-item-value ${
              !showParticipantVideoList && !isGrid ? "" : "inactive-icon"
            }`}
            style={{ paddingRight: "10px", fontSize: "26px" }}
            onClick={() => {
              onClickAttendeesList(false);
              switchGridView(false);
            }}
          >
            <i className="icon-ViewPresenter" />
          </span>
          <span
            className={`video-header-timer-item-value ${
              showParticipantVideoList && !isGrid ? "" : "inactive-icon"
            }`}
            style={{ paddingRight: "10px", fontSize: "26px" }}
            onClick={() => {
              onClickAttendeesList(true);
              switchGridView(false);
            }}
          >
            <i className="icon-ViewComposite" />
          </span>
          <span
            className={`video-header-timer-item-value ${
              !showParticipantVideoList && isGrid ? "" : "inactive-icon"
            }`}
            style={{ paddingRight: "20px", fontSize: "26px" }}
            onClick={() => {
              switchGridView(true);
              onClickAttendeesList(false);
            }}
          >
            <i className="icon-ViewGrid" />
          </span>
        </div>
        <div className="video-header-timer-item video-header-timer-item-pointer">
          <span className="video-header-timer-item-value">
            <i className="icon-Seats"></i>
            <div className="attendee-count">{participantListCount}</div>
          </span>
        </div>
      </div>
      <div className="video-header-timer-block">
        <div className="video-header-timer-item">
          <span className="video-header-timer-item-value">
            <If
              condition={
                isHostJoined && meetingStatus === MEETING_STATUS.STARTED
              }
              render={() => (
                <div>
                  <CountDown
                    serverNow={serverTime * 1000}
                    serverEndTime={Number(event.eventDate?.seconds) * 1000}
                    callbackCount={Number(event.eventDuration) - waitingSeconds}
                    callback={() => handleMeetingCountDownCallback()}
                  />
                </div>
              )}
            />
            <If
              condition={
                isHostJoined &&
                meetingStatus === MEETING_STATUS.IN_END_OF_MEETING
              }
              render={() => (
                <div className="timer-alert">
                  <CountDown
                    serverNow={serverTime * 1000}
                    serverEndTime={Number(event.eventDate?.seconds) * 1000}
                    callbackCount={Number(event.eventDuration)}
                    callback={() => handleMeetingCountDownCallback()}
                  />
                </div>
              )}
            />
            <If
              condition={
                isHostJoined && meetingStatus === MEETING_STATUS.IN_EXTRA_TIME
              }
              render={() => formatSecondsAsMinutes(Number(event.eventDuration))}
            />
          </span>
        </div>
        <If
          condition={
            isHostJoined && meetingStatus === MEETING_STATUS.IN_EXTRA_TIME
          }
          render={() => (
            <div className="video-header-timer-item">
              <span className="video-header-timer-item-value">
                <div className={isHost ? "timer-alert" : ""}>
                  <CountDown
                    serverNow={serverTime * 1000}
                    serverEndTime={
                      (Number(event.eventDate?.seconds) +
                        Number(event.eventDuration)) *
                      1000
                    }
                    callbackCount={extraTime}
                    callback={() => handleForceEndMeetingCountDownCallback()}
                  />
                </div>
              </span>
            </div>
          )}
        />
        <If
          condition={!showParticipantList}
          render={() => (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleParticipantList()}
            >
              <FontAwesomeIcon icon={isHost ? faEllipsisV : faComments} />
            </div>
          )}
        />
      </div>
    </div>
  </div>
);

export default VideoHeader;
