import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Notifier from "react-desktop-notification";
import { withFirebase } from "../../service/firebase";
import Header from "../Header";
import Footer from "../Footer";
import { If } from "common";
import { isSafari } from "../../helper/browser-detector";

import "./Layout.css";

const Layout = ({ children, history, firebase, ...rest }) => {
  const showHeaderFooter =
    history.location.pathname !== "/meeting" &&
    history.location.pathname !== "/stripe/return" &&
    history.location.pathname !== "/stripe/refresh";

  useEffect(() => {
    const listener = firebase.onAuthUserListener((authUser) => {
      if (!isSafari) {
        firebase.getToken();
        firebase
          .onMessageListener()
          .then((payload) => {
            Notifier.start(
              payload.notifiaction.title,
              payload.notification.body,
              "https://app.vidiolo.com",
              payload.notification.image
            );
          })
          .catch((err) => console.log("failed: ", err));
      }
    });

    return () => {
      if (listener) {
        listener();
      }
    };
  }, []);

  return (
    <React.Fragment>
      <If condition={showHeaderFooter} render={() => <Header {...rest} />} />
      <div className="vidiolo-layout">{children}</div>
      <If condition={showHeaderFooter} render={() => <Footer />} />
    </React.Fragment>
  );
};

export default compose(withRouter, withFirebase)(Layout);
