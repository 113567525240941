const displayNameCreator = (displayName, email) => {
  if (displayName != null) {
    return displayName;
  } else if (email != null) {
    const name = [];
    let result = "";
    const pre = email.split("@")[0];
    name = pre?.split(".") ?? [""];

    for (var i = 0; i < name.length; i++) {
      name[i] =
        name[i].substring(0, 1).toUpperCase() +
        name[i].substring(1, name[i].length);
      result += name[i] + " ";
    }
    return result.trim();
  } else {
    return "";
  }
};

const displayInitialsCreator = (displayName, email) => {
  const dN = displayNameCreator(displayName, email);
  const split = dN?.trim()?.split(" ") ?? [];
  let acronym = "";
  for (var i = 0; i < split.length; i++) {
    if (split[i].length > 0) {
      acronym += split[i][0];
    }
    if (acronym.length == 3) {
      break;
    }
  }
  return acronym;
};

export { displayNameCreator, displayInitialsCreator };
