import React from 'react';

export const PaperClip = () => (
  <svg width='13' height='18' viewBox='0 0 13 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.31675 1.512C5.25165 0.397777 7.64654 0.397681 9.57788 1.52552C11.4907 2.64256 12.6667 4.69116 12.6667 6.90629V10.859C12.6667 11.3192 12.2937 11.6923 11.8334 11.6923C11.3732 11.6923 11.0001 11.3192 11.0001 10.859V6.90629C11.0001 5.28366 10.1386 3.78301 8.73741 2.96475C7.32547 2.14022 5.56864 2.13849 4.14847 2.95631C2.72041 3.77866 1.83341 5.30577 1.83341 6.94993V12.7184C1.83341 13.7914 2.46603 14.7636 3.4471 15.1982C5.24026 15.9925 7.25766 14.6796 7.25766 12.7184V7.36217C7.25766 6.71607 6.53347 6.33451 6.00057 6.69983C5.78215 6.84956 5.6516 7.09735 5.6516 7.36217V11.4359C5.6516 11.8961 5.2785 12.2692 4.81826 12.2692C4.35803 12.2692 3.98493 11.8961 3.98493 11.4359V7.36217C3.98493 6.54774 4.38645 5.78567 5.05818 5.32517C6.6971 4.20163 8.92432 5.37511 8.92432 7.36217V12.7184C8.92432 15.8848 5.66718 18.0044 2.77209 16.722C1.18812 16.0204 0.166748 14.4508 0.166748 12.7184V6.94993C0.166748 4.70592 1.37537 2.62995 3.31675 1.512Z'
      fill='#19A0FF'
      fillOpacity='0.5'
    />
  </svg>
);
