import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import ReduxDevTools from "../dev-tools/ReduxDevTools";
import appReducer from "./reducers";

const rootReducer = combineReducers(appReducer);
const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    console.error(error); //eslint-disable-line
  },
});

const middlewares = [sagaMiddleware, thunk];

const configureStore = () => {
  const enhancerArgs = [applyMiddleware(...middlewares)];

  if (process.env.NODE_ENV !== "production") {
    enhancerArgs.push(ReduxDevTools.instrument());
  }

  const enhancer = compose(...enhancerArgs);

  const store = createStore(rootReducer, enhancer);

  store.runSaga = sagaMiddleware.run;

  return store;
};

export default configureStore;
