import React from "react";
import ReactDOM from "react-dom";
import Firebase, { FirebaseContext } from "./service/firebase";
import App from "./app";

import "assets/vidiolo_font_src/style.css";
import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
