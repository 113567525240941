import React from 'react';
import { compose } from 'recompose';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withFirebase } from '../../service/firebase';
import { withAuthorization } from '../../service/session';

import {
  GridContainer,
  GridItem,
} from '../../common';

const Stripe = (props) => {
  React.useEffect(() => {
    const {match: {params}} = props;
    let listener = null;
    const updateStripeAccount = props.firebase.updateStripeConnectedAccount();
    listener = props.firebase.onAuthUserListener(
      authUser => {
        if (params.type === 'return') {
          if (authUser.stripeConnectedAccount) {
            updateStripeAccount({
              'stripeConnectedAccountId': authUser.stripeConnectedAccount?.id
            }).then((result) => {
              props.history.push('/profile');
            })
            .catch((error) => {
              console.error('error', error);
            }) 
          } else {
            props.history.push('/profile');
          }
        } else if (params.type === 'refresh') {
          props.history.push('/profile');
        }
      },
      error => {
        console.error(error);
      }
    );

    return () => {
      if (listener) {
        listener();
      }
    }
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <LinearProgress />
        <h6 style={{textAlign: 'center'}}>
          Loading...
        </h6>
      </GridItem>
    </GridContainer>
  );
};


const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
)(Stripe);
