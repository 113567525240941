import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "../../common";

const StripePayment = ({ onSuccess, onError, handleOnSubmit }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!elements) {
      return;
    }

    handleOnSubmit();
    setLoading(true);

    try {
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required",
      });
      if (result?.paymentIntent?.status === "succeeded") {
        onSuccess();
      } else {
        onError(result);
      }
      setLoading(false);
    } catch (error) {
      onError(error);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Button
          round
          color="primary"
          disabled={!stripe || loading}
          type="submit"
        >
          {loading ? "Loading..." : "Pay"}
        </Button>
      </div>
    </form>
  );
};

export default StripePayment;
