/* eslint-disable */
import React from "react";
import { oneOf, bool, node } from "prop-types";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "../../common";
import { ReactComponent as Logo } from "../../assets/img/vidiolo_logo.svg";

import styles from "../../assets/jss/material-kit-pro-react/components/footerStyle";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

const Footer = (props) => {
  const { children, content, theme, big, className } = props;
  const classes = useStyles();
  const themeType =
    theme === "transparent" || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined,
  });
  const aClasses = classNames({
    [classes.a]: true,
  });

  return (
    <footer className={footerClasses}>
      <div>
        {children !== undefined ? (
          <div>
            <div className={classes.content}>{children}</div>
            <hr />
          </div>
        ) : (
          " "
        )}
        <div>
          <div className={classes.left}>
            <Link to="/" className={classes.footerBrand}>
              <Logo className="vidiolo-logo vidiolo-logo-orginal" />
            </Link>
          </div>
          <div className={classes.rightLinks}>
            <ul>
              <li>
                <Button
                  href="/"
                  target="_blank"
                  color="twitter"
                  justIcon
                  simple
                >
                  <i className="fab fa-twitter" />
                </Button>
              </li>
              <li>
                <Button
                  href="/"
                  target="_blank"
                  color="dribbble"
                  justIcon
                  simple
                >
                  <i className="fab fa-dribbble" />
                </Button>
              </li>
              <li>
                <Button
                  href="/"
                  target="_blank"
                  color="instagram"
                  justIcon
                  simple
                >
                  <i className="fab fa-instagram" />
                </Button>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
};

Footer.propTypes = {
  theme: oneOf(["dark", "white", "transparent"]),
  big: bool,
};

export default Footer;
