import configureStore from '../../store';
import rootSaga from '../../store/sagas';
import showDevTools from '../../dev-tools';

const DEV_TOOLS = 'DEV_TOOLS';

const initDevTools = ({store}) => {
  window[DEV_TOOLS] = {
    showDevTools : () => showDevTools(store)
  };
};

const bootrapService = () => {
  const store = configureStore();
  store.runSaga(rootSaga());

  window.store = store;

  initDevTools({store});

  return store;
};

export default bootrapService;