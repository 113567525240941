import React, { useState, useRef, useEffect } from "react";
import { findDOMNode } from "react-dom";
import ReactPlayer from "react-player";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExpand,
  faPlay,
  faPause,
  faVolumeUp,
  faVolumeDown,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import screenfull from "screenfull";
import "./VidioloVideoPlayer.css";
import { VIDEO_RECORDING_MODE } from "../../constants/video";

const CustomSlider = withStyles({
  root: {
    color: "red",
    height: 5,
  },
  thumb: {
    height: 0,
    width: 0,
    backgroundColor: "transparent",
    border: 0,
    "&:focus, &:hover": {
      boxShadow: "none",
    },
    "&$active": {
      boxShadow: "none",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 5,
    borderRadius: 4,
  },
  rail: {
    height: 5,
    borderRadius: 4,
    backgroundColor: "gray",
  },
})(Slider);

const CustomVolumeSlider = withStyles({
  root: {
    color: "white",
    height: 5,
    marginLeft: 10,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: "white",
    border: 0,
    marginTop: -3,
    marginLeft: -5,
    "&:focus, &:hover": {
      boxShadow: "none",
    },
    "&$active": {
      boxShadow: "none",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 5,
    borderRadius: 4,
  },
  rail: {
    height: 5,
    borderRadius: 4,
    backgroundColor: "gray",
  },
})(Slider);

const VidioloVideoPlayer = ({
  url,
  width,
  height,
  backgroundImage,
  videoMode,
}) => {
  const player = useRef();
  const playerContainer = useRef();
  const [state, setState] = useState({
    url: null,
    pip: false,
    playing: true,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    isFullscreen: false,
  });

  useEffect(() => {
    screenfull.on("change", () => {
      setState((prevState) => ({
        ...prevState,
        isFullscreen: screenfull.isFullscreen,
      }));
    });
    return () => {
      screenfull.off("change", console.log);
    };
  }, []);

  const handleClickFullscreen = () => {
    if (screenfull.isFullscreen) {
      screenfull.exit();
    } else {
      screenfull.request(findDOMNode(playerContainer.current));
    }
  };

  const handleSeekChange = (value) => {
    setState((preState) => ({
      ...preState,
      played: parseFloat(value / 100),
    }));
    player.current.seekTo(parseFloat(value / 100));
  };

  const handleProgress = (currentState) => {
    console.log("onProgress", currentState);
    // We only want to update time slider if we are not currently seeking
    if (!currentState.seeking) {
      setState((preState) => ({ ...preState, ...currentState }));
    }
  };

  const handlePlay = () => {
    console.log("onPlay");
    setState((prevState) => ({ ...prevState, playing: true }));
  };

  const handlePause = () => {
    console.log("onPause");
    setState((prevState) => ({ ...prevState, playing: false }));
  };

  const handlePlayPause = () => {
    setState((prevState) => ({ ...prevState, playing: !prevState.playing }));
  };

  const handleVolumeChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      volume: parseFloat(value / 100),
    }));
  };

  const isVideoOnImage =
    videoMode === VIDEO_RECORDING_MODE.VIDEO_ON_IMAGE ||
    videoMode === VIDEO_RECORDING_MODE.AUDIO_ON_IMAGE;

  return (
    <div className="vidiolo-video-player-container" ref={playerContainer}>
      <div
        className="vidiolo-video-player-screen"
        style={
          isVideoOnImage && backgroundImage
            ? {
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: state.isFullscreen
                  ? "100%"
                  : Number(window.innerWidth) > 495
                  ? "495px"
                  : Number(window.innerWidth) + "px",
                height: state.isFullscreen
                  ? "100%"
                  : Number(window.innerWidth) > 495
                  ? "495px"
                  : Number(window.innerWidth) + "px",
              }
            : null
        }
      >
        <div
          className={classnames({
            "small-video": isVideoOnImage,
          })}
          style={
            state.isFullscreen && !isVideoOnImage
              ? {
                  width: "100%",
                  height: "100%",
                }
              : null
          }
        >
          <ReactPlayer
            ref={player}
            height={isVideoOnImage || state.isFullscreen ? "100%" : height}
            width={isVideoOnImage || state.isFullscreen ? "100%" : width}
            url={url}
            playing={state.playing}
            light={state.light}
            volume={state.volume}
            muted={state.muted}
            onReady={() => console.log("onReady")}
            onStart={() => console.log("onStart")}
            onPlay={handlePlay}
            onPause={handlePause}
            onBuffer={() => console.log("onBuffer")}
            onSeek={(e) => console.log("onSeek", e)}
            onError={(e) => console.log("onError", e)}
            onProgress={handleProgress}
          />
        </div>
      </div>
      <div className="vidiolo-video-player-controls">
        <div className="timeline">
          <CustomSlider
            color="secondary"
            value={state.played * 100}
            size="medium"
            min={0}
            max={99}
            onChange={(event, value) => handleSeekChange(value)}
          />
        </div>
        <div className="control-buttons">
          <div style={{ display: "flex" }}>
            <button onClick={() => handlePlayPause()}>
              {!state.playing && <FontAwesomeIcon icon={faPlay} />}
              {state.playing && <FontAwesomeIcon icon={faPause} />}
            </button>
            <div className="volume-control">
              <div className="volume-control-volume-icon">
                {state.volume >= 0.5 && <FontAwesomeIcon icon={faVolumeUp} />}
                {state.volume < 0.5 && state.volume > 0 && (
                  <FontAwesomeIcon icon={faVolumeDown} />
                )}
                {state.muted ||
                  (state.volume === 0 && (
                    <FontAwesomeIcon icon={faVolumeMute} />
                  ))}
              </div>
              <CustomVolumeSlider
                color="secondary"
                value={state.volume * 100}
                size="medium"
                min={0}
                max={99}
                onChange={(event, value) => handleVolumeChange(value)}
              />
            </div>
          </div>
          <div>
            <button onClick={() => handleClickFullscreen()}>
              <FontAwesomeIcon icon={faExpand} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VidioloVideoPlayer;
