import { MEETING_STATUS } from "../../../constants/video";

const formatSecondsAsMinutes = (time, persistEmpty) => {
  if (time <= 0) {
    return persistEmpty ? '' : '0:00';
  }

  let sec = time >= 60 ? time % 60 : time;
  let min = Math.floor(time / 60);
  const hour = Math.floor(time / 3600);

  if (hour >= 1) {
    min = Math.floor(time % 3600 / 60);
  }

  if (min < 1) {
    min = hour > 0 ? '00' : '0';
  }

  if (sec.toString().length === 1) {
    sec = `0${sec}`;
  }

  if (Number(min) !== 0 && min.toString().length === 1) {
    min = `0${min}`;
  }

  return hour >= 1 ? `${hour}:${min}:${sec}` : `${min}:${sec}`;
};

const getTimes = (event, waitingSeconds, extraTime, serverTime) => {
  const isStarted = Number(serverTime.seconds) >= Number(event.eventDate.seconds);
  const isCompleted = serverTime.seconds >= Number(event.eventDate.seconds) + Number(event.eventDuration);
  const eventTime = Number(event.eventDate.seconds) + Number(event.eventDuration);

  const isInEndOfMeeting = serverTime.seconds > eventTime - waitingSeconds && serverTime.seconds < eventTime;
  const isInExtraMeeting = eventTime < serverTime.seconds && serverTime.seconds < (eventTime + (extraTime * 60));
  
  return {
    serverTime: serverTime.seconds,
    isStarted,
    isCompleted,
    isInEndOfMeeting,
    isInExtraMeeting,
    eventTime
  };
}

const getInitialMeetingStatus = (event, waitingSeconds, extraTime, serverTime) => {
  const {isStarted, isInEndOfMeeting, isInExtraMeeting} = getTimes(event, waitingSeconds, extraTime, serverTime);

  if (!isStarted) {
    return MEETING_STATUS.NOT_STARTED;
  } else if (isInExtraMeeting) {
    return MEETING_STATUS.IN_EXTRA_TIME;
  } else if (isInEndOfMeeting) {
    return MEETING_STATUS.IN_END_OF_MEETING;
  } 
/*     else if (isCompleted) { TODO: We need extra information if meeting has extra time
    return MEETING_STATUS.COMPLETED;
  } */

  return MEETING_STATUS.STARTED;
}

export {
    formatSecondsAsMinutes,
    getTimes,
    getInitialMeetingStatus
}