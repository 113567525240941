/* eslint react/jsx-filename-extension : off */

import React from 'react';
import {render} from 'react-dom';
import ReduxDevTools from './ReduxDevTools';

const WAIT_FOR = 100;

const showDevTools = store => {
  const popup = window.open(null, 'ReduxDevTools', 'menubar=no,location=no,resizable=yes,scrollbars=no,status=no');

  popup.location.reload();

  setTimeout(() => {
    popup.document.title = 'Redux Dev Tools';
    popup.document.write('<style>body { margin : 0; }</style>');
    popup.document.write('<div id="react-devtools-root"></div>');

    render(
      <ReduxDevTools store={store} />,
      popup.document.getElementById('react-devtools-root')
    );
  }, WAIT_FOR);
};

export default showDevTools;
