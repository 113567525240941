import React, { useEffect, useState } from "react";
import {
  Chat,
  Channel,
  Thread,
  Window,
  Streami18n,
  enTranslations,
} from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";

import { useChecklist } from "./ChecklistTasks";
import { LiveEventMessage } from "./components/LiveEventMessage/LiveEventMessage";
import { LiveEventChannelSwitch } from "./components/LiveEventChannelSwitch/LiveEventChannelSwitch";
import { LiveEventMessageInput } from "./components/LiveEventChannelFooter/LiveEventMessageInput";
import { If } from "common";

import "./video-chat.css";

const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    ...enTranslations,
    Mute: "Pin Message",
    Unmute: "Unpin Message",
  },
});

const VideoChat = ({ chatClient, channelName, targetOrigin }) => {
  const [channel, setChannel] = useState(null);
  const [currentTheme, setCurrentTheme] = useState("light");

  useEffect(() => {
    if (!channel) {
      const getChannel = async () => {
        const newChannel = await chatClient.channel("livestream", channelName, {
          name: "Test",
        });

        await newChannel.watch();
        setChannel(newChannel);
      };

      getChannel();
    }

    return () => {
      setChannel(null);
      chatClient.disconnectUser();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const handleThemeChange = (data) => {
      if (data === "light" || data === "dark") {
        setCurrentTheme(data);
      }
    };

    window.addEventListener("message", ({ data }) => handleThemeChange(data));
    return () =>
      window.removeEventListener("message", ({ data }) =>
        handleThemeChange(data)
      );
  }, []);

  useChecklist(chatClient, targetOrigin);

  return (
    <div className="main-container">
      <If
        condition={Boolean(channel)}
        render={() => (
          <Chat
            client={chatClient}
            i18nInstance={i18nInstance}
            theme={`livestream ${currentTheme}`}
          >
            <Channel channel={channel}>
              <Window hideOnThread>
                <LiveEventChannelSwitch />
              </Window>
              <Thread
                additionalMessageListProps={{ TypingIndicator: () => null }}
                additionalMessageInputProps={{ grow: true }}
                Message={LiveEventMessage}
                Input={LiveEventMessageInput}
              />
            </Channel>
          </Chat>
        )}
      />
    </div>
  );
};

export default VideoChat;
