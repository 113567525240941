import React, {useState, useEffect, useRef} from 'react';
import {number, bool, func} from 'prop-types';
import {formatSecondsAsMinutes} from '../VideoCall/helper/helper';

const displayName = 'VideoCountDown';

const propTypes = {
  callback : func,
  callbackCount : number,
  highlightCount    : number,
  persistEmpty      : bool,
  serverEndTime     : number.isRequired,
  serverNow         : number.isRequired
};

const defaultProps = {
  callback : () => null,
  highlightCount    : null,
  persistEmpty      : false,
  callbackCount : null
};

const useAnimationFrame = callback => {
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animate = time => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(requestRef.current);
  }, []); // eslint-disable-line
};

const VideoCountDown = ({
  callback,
  callbackCount,
  serverEndTime,
  serverNow,
  persistEmpty
}) => {
  const [time, setTime] = useState(null);
  const timerStart = useRef(Date.now());
  const didCallBack = useRef(false);
  const currentServerTime = useRef(serverEndTime);
  const currentCallbackCount = useRef(callbackCount);

  useEffect(() => {
    currentServerTime.current = serverEndTime;
  }, [serverEndTime]);

  useEffect(() => {
    currentCallbackCount.current = callbackCount;
    didCallBack.current = false;
  }, [callbackCount]);

  // eslint-disable-next-line complexity
  useAnimationFrame(() => {
    const elapsed = Date.now() - timerStart.current;
    const currentTime = currentServerTime.current - (serverNow + elapsed);
    // eslint-disable-next-line no-magic-numbers
    const count = Math.ceil(currentTime / 1000);
    const innerText = formatSecondsAsMinutes(count < 0 ? Math.abs(count) : count, persistEmpty);

    if (!didCallBack.current && Number(currentCallbackCount.current) === Math.abs(count)) {
      callback();
      didCallBack.current = true;
    }

    if (time !== count) {
      setTime(innerText);
    }
  });

  return (
    <React.Fragment>
      {time}
    </React.Fragment>
  );
};

VideoCountDown.displayName = displayName;
VideoCountDown.propTypes = propTypes;
VideoCountDown.defaultProps = defaultProps;

export default VideoCountDown;
