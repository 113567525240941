import {
  all,
  takeEvery
} from 'redux-saga/effects';
import {ActionTypes as MeetingActionTypes} from '../actions/meeting';

const joinMeetingSaga = function* logoutSaga () {
  
};

export default function* () {
  yield all([
    takeEvery(MeetingActionTypes.UPDATE_MEETING, joinMeetingSaga)
  ]);
}
