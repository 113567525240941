import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VIDEO_CV_TYPE } from "../../../constants/video";

const VideoCVCardTitle = ({ data }) => {
  const getTitle = useCallback(() => {
    const { cvSectionType, institution, customTitle } = data;

    switch (cvSectionType) {
      case VIDEO_CV_TYPE.aboutMe.KEY:
      case VIDEO_CV_TYPE.cover.KEY:
      case VIDEO_CV_TYPE.languages.KEY:
      case VIDEO_CV_TYPE.skills.KEY:
      case VIDEO_CV_TYPE.licenses.KEY:
      case VIDEO_CV_TYPE.certificates.KEY:
        return VIDEO_CV_TYPE[cvSectionType].TITLE;
      case VIDEO_CV_TYPE.custom.KEY:
        return customTitle;
      default:
        return institution;
    }
  }, [data]);

  return (
    <div className="video-cv-title-container">
      <div className="video-cv-title-icon">
        {VIDEO_CV_TYPE[data.cvSectionType] && (
          <FontAwesomeIcon icon={VIDEO_CV_TYPE[data.cvSectionType].ICON} />
        )}
      </div>
      <div className="video-cv-title-text">{getTitle()}</div>
    </div>
  );
};

export default VideoCVCardTitle;
