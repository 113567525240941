import React, { useState } from 'react';
import './LiveEventChannelSwitch.css';

import { LiveChat } from '../../../../assets/videoChatAssets/LiveChat';
import { Pins } from '../../../../assets/videoChatAssets/Pins';
import { Attendees } from '../../../../assets/videoChatAssets/Attendees';
import { LiveChatBlue } from '../../../../assets/videoChatAssets/LiveChatBlue';
import { PinsBlue } from '../../../../assets/videoChatAssets/PinsBlue';
import { AttendeesBlue } from '../../../../assets/videoChatAssets/AttendeesBlue';
import { LiveEventChannelContainer } from '../LiveEventChannelContainer/LiveEventChannelContainer';

export const LiveEventChannelSwitch = () => {
  const [tab, setTab] = useState(1);

  return (
    <>
      {/* <div className='live-event-channel-switch__container'>
        <div onClick={() => setTab(1)}>{tab === 1 ? <LiveChatBlue /> : <LiveChat />}</div>
        <div onClick={() => setTab(2)}>{tab === 2 ? <PinsBlue /> : <Pins />}</div>
        <div onClick={() => setTab(3)}>{tab === 3 ? <AttendeesBlue /> : <Attendees />}</div>
      </div> */}
      <LiveEventChannelContainer tab={tab} />
    </>
  );
};
