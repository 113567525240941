import React from "react";
import VideoCVCardTitle from "./VideoCVCardTitle";

const VideoCVCoverCard = ({ data, isHeader }) => (
  <React.Fragment>
    <VideoCVCardTitle data={data} />
    <div>
      {!isHeader && (
        <div sx={12} className="font-size-xs">
          {data.description}
        </div>
      )}
    </div>
  </React.Fragment>
);

export default VideoCVCoverCard;
