import React, { useEffect, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import Star from "@material-ui/icons/StarRate";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withFirebase } from "../../service/firebase";
import {
  GridContainer,
  GridItem,
  Card,
  CardHeader,
  Info,
  Button,
  If,
  Snackbar,
  Dialog,
} from "../../common";
import CATEGORIES from "../../constants/category";
import { getDurationString } from "helper/time-helper";

import productStyle from "../../assets/jss/material-kit-pro-react/views/productStyle";
import blogsStyle from "../../assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle";
import cardStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/sectionCards";

const useStyles = makeStyles(productStyle);
const blogUseStyles = makeStyles(blogsStyle);
const cardsUseStyles = makeStyles(cardStyle);

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const PromoCampaign = (props) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const blogClasses = blogUseStyles();
  const cardClasses = cardsUseStyles();
  const query = useQuery();
  const [ticketData, setTicketData] = useState();
  const [eventData, setEventData] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [currentError, setCurrentError] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const campainPath = query.get("campaignPath");
    const ticketPath = query.get("ticketPath");
    const eventPath = query.get("eventPath");

    const getEventData = async (path) => {
      const currentEvent = await props.firebase.getDataByPath(path);
      setEventData(currentEvent.data());
    };

    const getCampaignData = async () => {
      const campaign = await props.firebase.getDataByPath(campainPath);
      const currentCampaign = campaign.data();

      if (currentCampaign) {
        getEventData(currentCampaign.eventPath);
      }
    };

    const getTicketData = async () => {
      const ticket = await props.firebase.getDataByPath(ticketPath);
      const currentTicket = ticket.data();

      if (currentTicket) {
        getEventData(eventPath);
        setTicketData(currentTicket);
      }
    };

    const listener = props.firebase.onAuthUserListener(
      (authUser) => {
        setCurrentUser(authUser);
        if (campainPath) {
          getCampaignData();
        } else if (ticketPath) {
          getTicketData();
        }
      },
      () => {
        if (campainPath) {
          getCampaignData();
        } else if (ticketPath && eventPath) {
          getEventData(eventPath);
        }
      }
    );

    return () => {
      listener();
    };
  }, []);

  const onClaimFreeTicket = useCallback(() => {
    setLoading(true);
    const campainPath = query.get("campaignPath");
    const ticketPath = query.get("ticketPath");

    if (campainPath) {
      const claimCampaignTicket = props.firebase.claimCampaignTicket();
      claimCampaignTicket({
        campaignPath: campainPath,
        userId: currentUser.userId,
        agoraUserId: currentUser.agoraUserId,
        imageUrl: currentUser.userImageUrl,
        displayName: currentUser.displayName,
      })
        .then((result) => {
          if (!result.data.error) {
            setOpenDialog(true);
          } else {
            setCurrentError(result.data.error);
          }
          setLoading(false);
        })
        .catch((error) => {
          setCurrentError(error);
          setLoading(false);
          console.error(error, "result error");
        });
    } else if (ticketPath) {
      const claimCampaignTicket = props.firebase.claimAssignedTicket();

      claimCampaignTicket({
        ticketPath: ticketPath,
        attendeeId: currentUser.userId,
        attendeeAgoraUserId: currentUser.agoraUserId,
        attendeeImageUrl: currentUser.userImageUrl,
        attendeeDisplayName: currentUser.displayName,
      })
        .then((result) => {
          if (!result.data.error) {
            setOpenDialog(true);
          } else {
            setCurrentError(result.data.error);
          }
          setLoading(false);
        })
        .catch((error) => {
          setCurrentError(error);
          setLoading(false);
          console.error(error, "result error");
        });
    }
  }, [currentUser]);

  const getErrorMessage = useCallback(() => {
    if (!currentUser) {
      return "You have to login in order to claim your ticket";
    }

    const ticketPath = query.get("ticketPath");

    if (ticketPath && !ticketData?.assignment?.recipientEmail) {
      return "This ticket has been already claimed.";
    }

    if (
      ticketPath &&
      currentUser?.email !== ticketData?.assignment?.recipientEmail
    ) {
      return `Email of logged in User and Recipient Email do not match. This ticket can only be claimed by ${ticketData?.assignment?.recipientEmail}`;
    }

    return currentError && currentError.message;
  }, [currentUser, ticketData, currentError, query]);

  return (
    <div className={classes.productPage}>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <div className={classNames(classes.main)}>
            <Card plain className={blogClasses.card}>
              <If
                condition={Boolean(eventData)}
                render={() => (
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <CardHeader image plain>
                        <a href="" onClick={(e) => e.preventDefault()}>
                          <img src={eventData.eventImageUrl} alt="..." />
                        </a>
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <Info>
                        <h6 className={blogClasses.cardCategory}>
                          {eventData.eventCategories
                            .map((category) => CATEGORIES[category].title)
                            .join(", ")}
                        </h6>
                      </Info>
                      <h3 className={blogClasses.cardTitle}>
                        <a href="" onClick={(e) => e.preventDefault()}>
                          {eventData.eventTitle}
                        </a>
                      </h3>
                      <div className={cardClasses.author}>
                        <a href="" onClick={(e) => e.preventDefault()}>
                          <img
                            src={eventData.eventOwnerImageUrl}
                            alt={eventData.eventOwnerDisplayName}
                            className={
                              cardClasses.imgRaised + " " + cardClasses.avatar
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingTop: "5px",
                            }}
                          >
                            <strong
                              style={{ marginLeft: "5px", lineHeight: "15px" }}
                            >
                              {eventData.eventOwnerDisplayName}
                            </strong>
                            <span>
                              <Star
                                style={{
                                  verticalAlign: "bottom",
                                  color: "orange",
                                }}
                              />
                              {eventData.eventOwnerNumRatings}
                            </span>
                          </div>
                        </a>
                      </div>
                      <p
                        className={blogClasses.description}
                        style={{ paddingTop: "20px" }}
                      >
                        {eventData.eventDescription}
                      </p>
                      <hr />
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-Date"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            {moment(eventData.eventDate.toDate()).format(
                              "ddd, DD MMM"
                            )}
                          </span>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-Clock"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            {moment(eventData.eventDate.toDate()).format(
                              "hh:mm A"
                            )}
                          </span>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-Duration"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            {getDurationString(eventData.eventDuration)}
                          </span>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-Language"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            English
                          </span>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-CreditCard"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            {eventData.eventPrice.eventPriceAmount}{" "}
                            {eventData.eventPrice.eventPriceCurrency}
                          </span>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} lg={4}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              fontSize: "14px",
                            }}
                          >
                            <Info>
                              <i
                                className="icon-Seats"
                                style={{ fontSize: "22px" }}
                              />
                            </Info>
                            {eventData.eventSeats} Seats
                          </span>
                        </GridItem>
                      </GridContainer>
                      <hr />
                      <If
                        condition={loading}
                        otherwise={() => (
                          <If
                            condition={!getErrorMessage()}
                            render={() => (
                              <Button
                                round
                                color="primary"
                                onClick={() => onClaimFreeTicket()}
                              >
                                Claim Ticket
                              </Button>
                            )}
                          />
                        )}
                        render={() => (
                          <React.Fragment>
                            <LinearProgress />
                            <p>Connecting...</p>
                          </React.Fragment>
                        )}
                      />
                      <Dialog
                        open={openDialog}
                        setOpen={setOpenDialog}
                        content="The ticket has been claimed."
                        onConfirm={() => {
                          window.location.href = "/my-events";
                        }}
                      />
                      <If
                        condition={getErrorMessage()}
                        render={() => (
                          <Snackbar
                            message={<span>{getErrorMessage()}</span>}
                            color="danger"
                            icon="info_outline"
                          />
                        )}
                      />
                    </GridItem>
                  </GridContainer>
                )}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(PromoCampaign);
