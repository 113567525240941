const CATEGORIES = {
  HEALTH_WELLNESS: {
    title: 'Health & Wellness',
    key: 'HEALTH_WELLNESS',
  },
  FITNESS_SPORT: {
    title: 'Fitness & Sport',
    key: 'FITNESS_SPORT',
  },
  PHOTOGRAPHY: {
    title: 'Photography',
    key: 'PHOTOGRAPHY',
  },
  LANGUAGE_CULTURE: {
    title: 'Language & Culture',
    key: 'LANGUAGE_CULTURE',
  },
  FASHION_BEAUTY: {
    title: 'Fashion & Beauty',
    key: 'FASHION_BEAUTY',
  },
  DANCE: {
    title: 'Dance',
    key: 'DANCE',
  },
  FOOD_DRINK: {
    title: 'Food & Drink',
    key: 'FOOD_DRINK',
  },
  MUSIC: {
    title: 'Music',
    key: 'MUSIC',
  },
  ART: {
    title: 'Art',
    key: 'ART',
  },
  PETS: {
    title: 'Pets',
    key: 'PETS',
  },
  LEARNING_WRITING: {
    title: 'Learning & Writing',
    key: 'LEARNING_WRITING',
  },
  GAMES: {
    title: 'Games',
    key: 'GAMES',
  },
  BOOKCLUB: {
    title: 'Bookclub',
    key: 'BOOKCLUB',
  },
  HOBBY_CRAFT: {
    title: 'Hobby & Craft',
    key: 'HOBBY_CRAFT',
  },
  DIGITAL_SKILLS: {
    title: 'Digital Skills',
    key: 'DIGITAL_SKILLS',
  },
  COMMERCIAL_SKILLS: {
    title: 'Commercial Skills',
    key: 'COMMERCIAL_SKILLS',
  },
  PERSONAL_GROWTH_PRODUCTIVITY: {
    title: 'Personal Growth & Productivity',
    key: 'PERSONAL_GROWTH_PRODUCTIVITY',
  },
  DIY: {
    title: 'DIY',
    key: 'DIY',
  },
  GENERAL: {
    title: 'General',
    key: 'GENERAL',
  },
};

export default CATEGORIES;
